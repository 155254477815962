<template>
	<div class="main">
		<div class="nav-lf">
		  <MusicType
		    :width="952"
		    :type="type"
		    :tabsList="tabsList"
		    :dropdownList="dropdownList"
		  ></MusicType>
		</div>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	export default {
		props: {},
		computed: {},
		components: {
			MusicType
		},
		data(){
			return {
				type: false,
				tabsList: [{
					name: `${this.$t('joinorganization')}`,
					url: '/personalCenter/joinOrganization/invitationCode'
				}
				],
				dropdownList: []
			}
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	.main{
		// color: palevioletred;
		margin-left: 28px;
		::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid #ffffff;
			// position: relative;
		}
		// ::v-deep .nav .el-menu-item.is-active::after{
		// 	position: absolute;
		// 	content: '';
		// 	background-color: #ffffff;
		// 	right: 0;
		// 	top: 50%;
		// 	transform: translate(0, -50%);
		// 	height: 30rpx;
		// 	width: 15rpx;
		// 	border-radius: 15px 0 0 15px;
		// }
	}
</style>